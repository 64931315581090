const INITIAL_STATE = {
	loading: false,
	user: {},
	auth_modal: false,
	formValues: {},
	selectedFields: {},
};

const reducers = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case 'SET_LOADING':
			return {
				...state,
				loading: action?.payload,
			};
		case 'SET_USER':
			return {
				...state,
				user: action?.payload,
			};
		case 'SET_AUTH_MODAL':
			return {
				...state,
				auth_modal: action?.payload,
			};
		case 'SET_SEARCH_FORM_VALUE':
			return {
				...state,
				formValues: action?.payload,
			};
		case 'SET_SELECT_FIELDS':
			return {
				...state,
				selectedFields: action?.payload,
			};
		default:
			return state;
	}
};
export default reducers;
