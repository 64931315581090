import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { AUTH_URL } from '../../utils/api';
import Axios from '../../utils/httpClient';
import { getToken } from '../../utils/tokenStorge';
import Header from './Header';
import SideBar from './SideBar';

const Layout = ({ children }) => {
	const token = getToken();
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const [response, setResponse] = useState(false);
	const [openSection, setOpenSection] = useState(null);

	const { user } = useSelector(s => s);
	useEffect(() => {
		if (!user?.id) getUser();

		if (!token) {
			navigate('/login');
		}
	}, []);
	const getUser = () => {
		Axios(AUTH_URL)
			.get(`/account/me/`)
			.then(r => {
				dispatch({ type: 'SET_USER', payload: r?.data ?? {} });
			})
			.catch(e => {})
			.finally(() => {});
	};

	useEffect(() => {
		const handleResize = () => {
			if (window.matchMedia('(max-width: 768px)').matches) {
				setResponse(true);
			} else {
				setResponse(false);
			}
		};

		handleResize(); // Initial check
		window.addEventListener('resize', handleResize); // Add resize listener

		return () => {
			window.removeEventListener('resize', handleResize); // Clean up listener on unmount
		};
	}, []);

	const toggleSection = section => {
		setOpenSection(prev => (prev === section ? null : section));
	};
	return (
		<div className='container2 relative flex w-full'>
			<div className={`fixed top-0 min-h-screen z-[991] ${response ? 'w-[65px]' : 'w-[317px]'} duration-250 transition-all ease-linear`}>
				<SideBar setOpenSection={setOpenSection} toggleSection={toggleSection} openSection={openSection} setResponse={setResponse} response={response} />
			</div>
			<div className={`overflow-hidden ${response ? 'ml-[65px] ' : 'ml-[317px] '} w-full min-h-screen relative duration-250 transition-all ease-linear`}>
				<div className=''>
					<Header toggleSection={toggleSection} setResponse={setResponse} response={response} setOpenSection={setOpenSection} />
				</div>

				<div className=' sm:p-[20px] p-[10px] bg-alabaster !pb-[100px] mt-[73px] min-h-[90vh]'>{children}</div>
			</div>
		</div>
	);
};

export default Layout;
