import { motion } from 'framer-motion';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Link, NavLink, useLocation, useNavigate } from 'react-router-dom';
import ArrowRight from '../../assets/svg/ArrowRight';
import DocNormal from '../../assets/svg/DocNormal';
import HomeIcon from '../../assets/svg/HomeIcon';
import Message from '../../assets/svg/Message';
import ProfileUserIcon from '../../assets/svg/ProfileUserIcon';
import SecuritySafeIcon from '../../assets/svg/SecuritySafeIcon';
import { removeToken } from '../../utils/tokenStorge';

const SideBar = ({ response = false, setResponse, setOpenSection, openSection, toggleSection = () => {} }) => {
	const { t } = useTranslation();
	const location = useLocation();
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const [openModal, setOpenModal] = useState(false);

	const sidebarItems = [
		{
			label: t('sidebar.home'),
			link: '#',
			icon: <HomeIcon />,
			iconactive: <HomeIcon fill={'#4680FF'} />,
			childs: [
				{
					label: t('sidebar.security'),
					link: '/',
				},
				{
					label: t('sidebar.organized-work'),
					link: '/organized-work',
					child: true,
				},
				// {
				//   label: "test",
				//   link: "/test",
				//   child: true,
				// },
				{
					label: "Immigrantlar haqida shaxssizlantirilgan ma'lumotlar",
					// link: "/immigrant-statistics",
					link: '#',
					disable_link: true,
				},
			],
		},
		{
			label: t('sidebar.info'),
			link: '#',
			icon: <DocNormal />,
			iconactive: <DocNormal fill={'#4680FF'} />,
			childs: [
				{
					label: t('sidebar.statistics/0'),
					link: '/statistics/0',
				},
				{
					label: t('sidebar.statistics/1'),
					link: '/statistics/1',
				},
				{
					label: t('sidebar.statistics/2'),
					link: '/statistics/2',
				},
				{
					label: t('sidebar.statistics/3'),
					link: '/statistics/3',
				},
			],
		},
		{
			label: t('sidebar.immigrant'),
			link: '#',
			icon: <DocNormal />,
			iconactive: <DocNormal fill={'#4680FF'} />,
			childs: [
				{
					label: t('immigrant.foreigners'),
					// link: "/statistics-immigrant/0",
					link: '#',
					disable_link: true,
				},
				{
					label: t('immigrant.long_term_foreigners'),
					// link: "/statistics-immigrant/1",
					link: '#',
					disable_link: true,
				},
				{
					label: t('immigrant.foreigners_who_left'),
					// link: "/statistics-immigrant/3",
					link: '#',
					disable_link: true,
				},
				{
					label: t('immigrant.foreigners_stayed_long_term'),
					// link: "/statistics-immigrant/4",
					link: '#',
					disable_link: true,
				},
			],
		},
		{
			label: 'Tashkillashtirilgan ish',
			link: '#',
			icon: <DocNormal />,
			iconactive: <DocNormal fill={'#4680FF'} />,
			childs: [
				{
					label: 'Xorijdagilar',
					link: '/organized-work/0',
				},
				{
					label: 'Qaytganlar',
					link: '/organized-work/2',
				},
			],
		},
		// {
		//   label: "Аналитика по пересечению границы",
		//   link: "#",
		//   icon: <DocIcon />,
		//   iconactive: <DocIcon fill={"#4680FF"} />,
		//   childs: [
		//     {
		//       label: "Граждане за рубежом",
		//       link: "/bordercrossing/citizens-abroad",
		//     },
		//     {
		//       label: "Граждане за рубежом (более 90 дней)",
		//       link: "/bordercrossing/citizens-abroaddays",
		//     },
		//     {
		//       label: "Вернувшиеся граждане",
		//       link: "/bordercrossing/returned-citizens",
		//     },
		//     // {
		//     // 	label: 'Иностранные граждане в стране',
		//     // 	link: '/bordercrossing/citizens-country',
		//     // },
		//   ],
		// },
		// {
		// 	label: 'Иммиграция',
		// 	link: '#',
		// 	icon: <DocIcon />,
		// 	iconactive: <DocIcon fill={'#4680FF'} />,
		// 	childs: [
		// 		{
		// 			label: 'Кетганлар',
		// 			link: '/immigration/to-leave',
		// 		},
		// 		{
		// 			label: 'Келганлар',
		// 			link: '/immigration/to-come',
		// 		},
		// 	],
		// },
		// {
		// 	label: 'Эмиргация',
		// 	link: '#',
		// 	icon: <DocIcon />,
		// 	iconactive: <DocIcon fill={'#4680FF'} />,
		// 	childs: [
		// 		{
		// 			label: 'Чиққанлар',
		// 			link: '/emigration/to-leave',
		// 		},
		// 		{
		// 			label: 'Кирганлар',
		// 			link: '/emigration/to-come',
		// 		},
		// 	],
		// },
		{
			label: t('sidebar.reports'),
			link: '#',
			icon: <DocNormal />,
			iconactive: <DocNormal fill={'#4680FF'} />,
			childs: [
				{
					label: t('home.abroad_uzb'),
					link: '/reports/abroad-citizen',
				},
				{
					label: t('home.abroad_uzb_back'),
					link: '/reports/abroad-backcitizen',
				},
				// {
				//   label: t("sidebar.child11"),
				//   link: "/reports/reports-minormigrants",
				// },
				// {
				//   label: t("sidebar.child22"),
				//   link: "/reports/reports-bornabroad",
				// },
				{
					label: "Xorijdagi fuqaroligi yo'q shaxslar",
					link: '/reports/reports-abroad-persons',
					// link: '#',
					disable_link: false,
				},
				{
					label: t('sidebar.child33'),
					link: '/reports/reports-guardians',
					// link: '#',
					disable_link: false,
				},
				{
					label: t('sidebar.child44'),
					// link: "/reports/reports-detained",
					link: '#',
					disable_link: true,
				},
				{
					label: t('sidebar.child55'),
					// link: "/reports/reports-killedabroad",
					link: '#',
					disable_link: true,
				},
				{
					label: t('sidebar.child66'),
					// link: "/reports/reports-renounced",
					link: '#',
					disable_link: true,
				},
			],
		},
		{
			label: t('sidebar.message'),
			link: '#',
			icon: <Message />,
			iconactive: <Message fill={'#4680FF'} />,
			childs: [
				{
					label: "Og'ir ahvolga tushgan ayollar",
					link: '/applications/women-in-distress',
					disable_link: true,
				},
			],
		},
		//     {
		//       label: "Qidirish",
		//       link: "/search",
		//       icon: <SearchIcon />,
		//       iconactive: <ProfileUserIcon fill={"#4680FF"} />,
		//     },
		{
			label: t('sidebar.separate_category'),
			link: '#',
			icon: <DocNormal />,
			iconactive: <DocNormal fill={'#4680FF'} />,
			childs: [
				{
					label: t('sidebar.separate_category/0'),
					link: '/separate-category/0',
				},
				{
					label: t('sidebar.separate_category/2'),
					link: '/separate-category/2',
				},
			],
		},
		{
			label: 'Voyaga yetmaganlar',
			link: '#',
			icon: <DocNormal />,
			iconactive: <DocNormal fill={'#4680FF'} />,
			childs: [
				{
					label: 'Voyaga yetmagan xorijdagi fuqarolar',
					link: '/minors/0',
				},
				{
					label: 'Hamrohi qaytgan voyaga yetmaganlar',
					link: '/minors/2',
				},
			],
		},
		{
			label: t('sidebar.inspectors'),
			link: '/inspectors',
			icon: <ProfileUserIcon />,
			iconactive: <ProfileUserIcon fill={'#4680FF'} />,
		},
		{
			label: t('sidebar.accesses'),
			link: '#',
			icon: <SecuritySafeIcon />,
			iconactive: <SecuritySafeIcon fill={'#4680FF'} />,
			childs: [
				{
					label: t('sidebar.organizations'),
					link: '/accesses/organizations',
				},
				{
					label: t('sidebar.employees'),
					link: '/accesses/employees',
				},
			],
		},
	];

	const variants = {
		open: { width: 317, opacity: 1 },
		closed: { width: 65, opacity: 1 },
	};

	// const toggleSection = section => {
	// 	setOpenSection(prev => (prev === section ? null : section));
	// };

	useEffect(() => {
		const path = window.location.pathname;
		const sectionIndex = sidebarItems.findIndex(item => item?.childs?.some(child => child.link === path));
		if (sectionIndex !== -1) {
			setOpenSection(sectionIndex);
		}
	}, [window.location.pathname]);

	const logOut = () => {
		removeToken();
		dispatch({ type: 'SET_USER', payload: {} });
		navigate('/login');
	};
	return (
		<>
			<motion.div
				initial={response ? 'closed' : 'open'}
				animate={response ? 'closed' : 'open'}
				variants={variants}
				className={`  bg-white custom-scrollbar  scrol sticky inset-x-0 top-0 p-[10px]  overflow-x-scroll ${
					response ? 'md:p-[10px]' : 'md:p-[20px]'
				} flex flex-col justify-between h-screen border-r border-r-gallery`}
			>
				<div className='  flex flex-col gap-4'>
					{response ? (
						<Link to='/'>
							<img src='/images/logo-response.svg' alt='logo' className='h-[45px] max-h-[45px] w-fit' />
						</Link>
					) : (
						<Link to='/'>
							<img src='/images/logo.svg' alt='logo' className='h-[45px] max-h-[45px] w-fit' />
						</Link>
					)}
					<div className='flex flex-col gap-2 mb-4'>
						{sidebarItems.map((item, index) => (
							<React.Fragment key={index}>
								{item?.link === '#' ? (
									<div>
										<div
											onClick={() => {
												toggleSection(index);
												setResponse(false);
											}}
											key={index}
											to={item.link}
											className={`cursor-pointer  hover:bg-wilfSand rounded-lg ${openSection === index ? 'bg-[#EDF3FF]' : ''}   ${
												response ? ' py-3 flex justify-center' : 'p-4'
											}`}
										>
											<div className={`flex items-center justify-between gap-2 `}>
												<div className='flex justify-center items-center gap-2'>
													<div>{openSection === index ? item.iconactive : item.icon}</div>
													<p
														className={`text-base ${openSection === index ? 'text-royalBlue' : 'text-shuttleGray'}  ${
															response ? 'hidden' : 'block'
														}`}
													>
														{item.label}
													</p>
												</div>
												{openSection === index ? (
													<ArrowRight
														fill={'#4680FF'}
														className={`rotate-90 duration-250 transition-all ease-linear min-w-[18px] max-w-[18px] ${
															response ? 'hidden' : 'inline-block'
														}`}
													/>
												) : (
													<ArrowRight
														className={`rotate-270 duration-250 transition-all ease-linear min-w-[18px] max-w-[18px] ${
															response ? 'hidden' : 'inline-block'
														}`}
													/>
												)}
											</div>
										</div>
										{/* transition-max-height  duration-300 */}
										<div
											className={`overflow-hidden  rounded-md px-2 ease-in-out  ${openSection === index && !response ? 'max-h-max ' : 'max-h-0'}  ${
												response
													? `!fixed z-[900]  ml-[60px] !h-[215px]  border-[#EFEFEF]   bg-[#fff]   text-white   overflow-y-auto bg-blue dark:bg-blueBack ${
															item?.type === 'top' ? 'mt-[-212px]' : 'mt-[-56px]'
													  }`
													: 'bg-white'
											}`}
										>
											<div className='w-full flex flex-col '>
												{item?.childs?.map((ch, ch_index) => {
													return (
														<div className=' w-full flex flex-col gap-[10px] mt-[10px] ' key={ch_index}>
															{ch?.disable_link === true ? (
																<div className='flex items-center pl-[5px] gap-[10px]  justify-start'>
																	{ch?.child ? (
																		<span className='pl-8 flex'>
																			{response ? null : (
																				<div className='max-w-6 max-h-6 min-w-6 min-h-6 flex items-center justify-center'>
																					<div className='border-[#B9BEC7] bg-white border-[2px] w-[5px] h-[5px]'></div>
																				</div>
																			)}
																			<span className={` select-none ${response ? '' : ''}`}>{ch?.label}</span>
																		</span>
																	) : (
																		<>
																			{response ? null : (
																				<div className='max-w-6 max-h-6 min-w-6 min-h-6 flex items-center justify-center'>
																					<div className='bg-[#B9BEC7] rounded-full w-1 h-1'></div>
																				</div>
																			)}
																			<span className={`opacity-50 select-none ${response ? '' : ''}`}>{ch?.label}</span>
																		</>
																	)}
																</div>
															) : (
																<NavLink to={ch.link} className={'flex  items-center pl-[5px] gap-[10px]  justify-start'}>
																	{ch?.child ? (
																		<span className='pl-8 flex'>
																			{response ? null : (
																				<div className='max-w-6 max-h-6 min-w-6 min-h-6 flex items-center justify-center'>
																					<div className='border-[#B9BEC7] bg-white border-[2px] w-[5px] h-[5px]'></div>
																				</div>
																			)}
																			<span className={` select-none ${response ? '' : ''}`}>{ch?.label}</span>
																		</span>
																	) : (
																		<>
																			{response ? null : (
																				<div className='max-w-6 max-h-6 min-w-6 min-h-6 flex items-center justify-center'>
																					<div className='bg-[#B9BEC7] rounded-full w-1 h-1'></div>
																				</div>
																			)}
																			<span className={` select-none ${response ? '' : ''}`}>{ch?.label}</span>
																		</>
																	)}
																</NavLink>
															)}
														</div>
													);
												})}
											</div>
										</div>
									</div>
								) : (
									<NavLink
										key={index}
										to={item.link}
										className={({ isActive }) =>
											isActive
												? `cursor-pointer  hover:bg-wilfSand rounded-lg flex items-center gap-2 !bg-zumthor ${
														response ? ' py-2 flex justify-center' : 'p-4'
												  }`
												: `cursor-pointer p-4 hover:bg-wilfSand rounded-lg flex items-center gap-2  ${response ? ' py-2 flex justify-center' : 'p-4'}`
										}
										onClick={() => {
											setOpenSection(null);
										}}
									>
										<div>{location.pathname === item.link ? item.iconactive : item.icon}</div>
										<p className={`text-base text-shuttleGray ${response ? 'hidden' : 'block'}`}>{item.label}</p>
									</NavLink>
								)}
							</React.Fragment>
						))}
					</div>
				</div>
				<div>
					<div
						className={`bottom-0 bg-white w-full flex cursor-pointer  hover:bg-wilfSand rounded-lg items-center gap-2 ${response ? ' py-2 flex justify-center' : 'p-4'}`}
						// onClick={logOut}
						onClick={() => setOpenModal(true)}
					>
						<img src='/images/logout.svg' alt='logout' />
						<div className={`text-[#5B6B79] text-[16px] font-normal leading-[20px] ${response ? 'hidden' : 'block'}`}>{t('sidebar.exit')}</div>
					</div>
					{openModal && (
						<div className='fixed inset-0 flex items-center justify-center bg-black bg-opacity-50'>
							<div className='bg-white rounded-lg shadow-lg p-6 w-80'>
								<h2 className='text-xl font-semibold mb-4'>{t('sidebar.exit-modal')}</h2>
								<div className='flex justify-end'>
									<button
										className='px-4 py-2 mr-2 text-white bg-red-500 rounded'
										onClick={() => {
											logOut();
										}}
									>
										{t('sidebar.yes')}
									</button>
									<button className='px-4 py-2 text-gray-700 bg-gray-200 rounded' onClick={() => setOpenModal(false)}>
										{t('sidebar.no')}
									</button>
								</div>
							</div>
						</div>
					)}
				</div>
			</motion.div>
		</>
	);
};

export default SideBar;
