import { Dropdown } from 'flowbite-react';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import MenuIcon from '../../assets/svg/MenuIcon';
import Notification from '../../assets/svg/Notification';
import SearchIcon from '../../assets/svg/SearchIcon';
import Switcher from '../uiComponents/Switcher';
import SlidingTab from './SlidingTab';
const Header = ({ setResponse, response = false, setOpenSection }) => {
	const { i18n, t } = useTranslation();
	const tabsRef = useRef([]);
	const navigate = useNavigate();
	const location = useLocation();

	const [activeTabIndex, setActiveTabIndex] = useState(0);
	const [tabUnderlineWidth, setTabUnderlineWidth] = useState(0);
	const [tabUnderlineLeft, setTabUnderlineLeft] = useState(0);

	let allTabs = [
		{
			id: 'text1',
			path: '/',
			name: t('header.text1'),
			childIdType: 'M',
		},
		{
			id: 'text2',
			path: '/?child-id-type=P',
			name: t('header.text2'),
			childIdType: 'P',
		},
	];

	let allTabs2 = [
		{
			id: 'text1',
			path: '/',
			name: t('immigrant.foreigners'),
			childIdType: 'M',
		},
		{
			id: 'text2',
			path: '/?child-id-type=P',
			name: t('immigrant.foreigners_who_left'),
			childIdType: 'P',
		},
	];
	let allTabs3 = [
		{
			id: 'text1',
			path: '/organized-work',
			name: t('header.text1'),
			childIdType: 'M',
		},
		{
			id: 'text2',
			path: '/organized-work?child-id-type=P',
			name: t('header.text2'),
			childIdType: 'P',
		},
	];
	useEffect(() => {
		if (activeTabIndex === null) {
			return;
		}

		const setTabPosition = () => {
			const currentTab = tabsRef.current[activeTabIndex];
			setTabUnderlineLeft(currentTab?.offsetLeft ?? 0);
			setTabUnderlineWidth(currentTab?.clientWidth ?? 0);
		};

		setTabPosition();
	}, [activeTabIndex, location, tabUnderlineWidth, tabUnderlineLeft]);

	const handleLanguageChange = lng => {
		i18n.changeLanguage(lng);
	};
	const toggleSection = section => {
		setOpenSection(prev => (prev === section ? null : section));
	};
	return (
		<div
			style={{
				width: `calc(${response ? '100% - 65px' : '100% - 317px'})`,
			}}
			className={`px-[20px] fixed py-3 h-[73px] mx-auto border-b bg-white border-b-gallery duration-250 transition-all ease-linear z-[900] 
	${response ? 'max-w-[1935px]' : 'max-w-[1683px]'}
	flex justify-between `}
		>
			<div className='flex gap-[15px]'>
				<button
					onClick={() => {
						setResponse(!response);
						// if (response) {
						// 	toggleSection();
						// }
					}}
					className=' cursor-pointer p-[10px] rounded-lg bg-alabaster'
				>
					<MenuIcon />
				</button>
				{location?.pathname === '/' || location?.pathname === '/returned/1' || location?.pathname === '/immigrant-statistics' || location?.pathname === '/organized-work' ? (
					location?.pathname === '/immigrant-statistics' ? (
						<SlidingTab
							tabsRef={tabsRef}
							tabUnderlineLeft={tabUnderlineLeft}
							tabUnderlineWidth={tabUnderlineWidth}
							allTabs={allTabs2}
							activeTabIndex={activeTabIndex}
							setActiveTabIndex={setActiveTabIndex}
						/>
					) : location?.pathname === '/organized-work' ? (
						<SlidingTab
							tabsRef={tabsRef}
							tabUnderlineLeft={tabUnderlineLeft}
							tabUnderlineWidth={tabUnderlineWidth}
							allTabs={allTabs3}
							activeTabIndex={activeTabIndex}
							setActiveTabIndex={setActiveTabIndex}
						/>
					) : (
						<SlidingTab
							tabsRef={tabsRef}
							tabUnderlineLeft={tabUnderlineLeft}
							tabUnderlineWidth={tabUnderlineWidth}
							allTabs={allTabs}
							activeTabIndex={activeTabIndex}
							setActiveTabIndex={setActiveTabIndex}
						/>
					)
				) : (
					''
				)}
				{/* <form className=' border-[#FFFFFF] border-[1px]  hover:border-[1px] hover:border-[#EFEFEF] cursor-pointer rounded-lg '>
					<input
						type='text'
						className='py-[10px] pr-[10px] pl-[40px] w-full  border-1 focus:border-[1px] hover:border-[1px] border-[#FFFF] hover:border-[] cursor-pointer focus:border-[#EFEFEF] rounded-lg  '
						style={{
							background: 'url(/images/search.svg)',
							backgroundRepeat: 'no-repeat',
							backgroundPositionY: 'center',
							backgroundPositionX: '10px',
						}}
					/>
				</form> */}
			</div>
			<div className='flex gap-[10px]'>
				<div
					className='flex justify-center items-center cursor-pointer'
					onClick={() => {
						navigate('/search');
					}}
				>
					<SearchIcon />
				</div>
				<Dropdown
					className='p-[10px] z-20 bg-alabaster outline-none'
					arrowIcon={false}
					renderTrigger={() => (
						<div className='cursor-pointer  p-[10px] rounded-lg bg-alabaster flex justify-center items-center h-[44px] w-[44px]'>
							{i18n.language === 'ru' ? <img src='/images/rus.svg' alt='' /> : <img src='/images/uzb.svg' alt='' />}
						</div>
					)}
				>
					<Dropdown.Item className='px-0' onClick={() => handleLanguageChange('uz')}>
						<img className='w-6 h-4' src='/images/uzb.svg' alt='' />
					</Dropdown.Item>
					<Dropdown.Item className='mt-2 px-0' onClick={() => handleLanguageChange('ru')}>
						<img src='/images/rus.svg' alt='' />
					</Dropdown.Item>
				</Dropdown>
				<button className='cursor-pointer  h-[44px] w-[44px] flex justify-center items-center rounded-lg bg-alabaster  dark:bg-shuttleGray'>
					{/* <SunIcon /> */}
					<Switcher />
				</button>
				<div className='cursor-pointer relative  w-[44px] h-[44px] flex  justify-center items-center'>
					<div className='flex justify-center items-center bg-royalBlue text-white rounded-full absolute  text-[12px] top-[6px] right-[6px] w-4 h-4'>9</div>
					<Notification />
				</div>
				<button className='w-[44px] h-[44px] rounded-full bg-[#E9F0FF] text-royalBlue'>U</button>
			</div>
		</div>
	);
};

export default Header;
