import axios from 'axios';
import { API_URL } from './api';
import { removeToken, setToken } from './tokenStorge';

axios.interceptors.request.use(
	config => config,
	e => Promise.reject(e)
);
axios.interceptors.response.use(
	response => response,
	e => {
		if (typeof e?.toJSON === 'function') {
			const error = e?.toJSON();

			if (error?.status === 401) {
				removeToken();
				window.location.href = '/login';
			}
			if (error?.message === 'Network Error') {
				// toast.warning(
				//   localStorage.getItem("i18nextLng") === "uz"
				//     ? "Internetingiz uzildi!"
				//     : "Нет подключения к Интернету!"
				// );
			}
			if (process.env.NODE_ENV === 'development') {
				// console.log(error);
			}
		}
		return Promise.reject(e);
	}
);
const Axios = (Api_Url, access_token) => {
	const isLocal = window.location.href.includes('localhost');
	let token = '';
	if (isLocal) {
		token = access_token
			? access_token
			: 'eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0b2tlbl90eXBlIjoiYWNjZXNzIiwiZXhwIjoxNzM0ODYxMjUxLCJpYXQiOjE3MzM5OTcyNTEsImp0aSI6IjljMzI2ZGE1MWM5YTRiMGNhN2U5YzNjNDAyODU3NGYwIiwidXNlcl9pZCI6NCwidXNlcm5hbWUiOiJtaWdyYXRpb25fYWRtaW4iLCJlbWFpbCI6IiIsImZ1bGxfbmFtZSI6IiAiLCJpc19zdXBlcnVzZXIiOmZhbHNlLCJpc19zdGFmZiI6dHJ1ZSwicGVybWlzc2lvbnMiOlsidmlld193b3JrYW5kY2FtZWJhY2siLCJ2aWV3X2V4cGVyaWVuY2UiLCJ2aWV3X3JlbGF0aXZlIiwidmlld19pbnN0aXR1dGlvbiIsInByZXZpb3VzX2Zpc2giLCJnZW5kZXJfaW5mb3JtYXRpb24iLCJ2aWV3X2FsaW1lbnQiLCJ2aWV3X2VkdWNhdGlvbiIsInZpZXdfbm9zdHJpZmlrYXRzaXlhIl0sInJvbGVzIjpbImJpb2dyYXBoaWNhbF9kYXRhX29mX3RoZV9taWdyYW50IiwiaW5mb3JtYXRpb25fYWJvdXRfbWlncmFudF9jcm9zc2luZ190aGVfYm9yZGVyIiwiaW5mb3JtYXRpb25fYWJvdXRfdGhlX3BsYWNlX29mX3Jlc2lkZW5jZV9hbmRfd29ya19vZl90aGVfbWlncmFudCIsImFkZGl0aW9uYWxfaW5mb3JtYXRpb25fb2ZfdGhlX21pZ3JhbnQiLCJhZG1pbiIsImVtcGxveW1lbnRfaW5mb3JtYXRpb24iXX0.izUinY3NGUcIihId5g_lzXTU3fDNuxyaM5qBa1y4hQvf42TgVWEFOB7pK5eqfyNRFG0JIEPXkWfHBbIsc08ELWKBK2EDMUU2zGiUbfcggTdv3joyAlHFmdS42XSjaMtzif5i_1YN1ykV8vYaXSEBvXVanIDMuGqy95hP9ezBH3vZ6QG6AR3yEugDhK-wBJoEhOJYov8hWd5Yy2VBoZnmhTEigC7arI1DvMFgIrkXQhAspFJlYynUX1B27SYejn-RT49RWOb3P_FPys97B9oApE7A35XIbIZpIwsWGDV6LANNctXY6lY6jYzV9MjATcr-YUxyxXrpcBOTAMi0bE1V7g';
		setToken(token);
	} else {
		token = access_token ? access_token : localStorage.getItem('token');
	}

	const defaultOptions = {
		baseURL: Api_Url ? Api_Url : API_URL,
		headers: token
			? {
					Authorization: `Bearer ${token}`,
					lan: localStorage.getItem('i18nextLng') || 'uz',
			  }
			: { lan: localStorage.getItem('i18nextLng') || 'uz' },
		params: { lan: localStorage.getItem('i18nextLng') || 'uz' },
	};

	return {
		get: (url, options = {}) => axios.get(url, { ...defaultOptions, ...options }),
		post: (url, data, options = {}) => axios.post(url, data, { ...defaultOptions, ...options }),
		put: (url, data, options = {}) => axios.put(url, data, { ...defaultOptions, ...options }),
		delete: (url, options = {}) => axios.delete(url, { ...defaultOptions, ...options }),
	};
};
export default Axios;
